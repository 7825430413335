import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import About from "../components/about-section"

const AboutPage = () => (
  <Layout>
    <SEO title="About Us" />
    <About />
    <Contact />
  </Layout>
)

export default AboutPage
