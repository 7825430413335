import React from "react"
import styled from "styled-components"
import AboutImage from "./img/about-img"

const AboutContainer = styled.div`
  box-sizing: border-box;
  background: #305244;
  padding: 0 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 450px) {
    padding: 0 0 16px;
  }
  @media screen and (min-width: 500px) {
    padding: 0 0 16px;
  }
  @media screen and (min-width: 650px) {
    padding: 0 0 32px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 0 32px;
  }
  @media screen and (min-width: 950px) {
    padding: 0 0 48px;
  }
  @media screen and (min-width: 1050px) {
    padding: 0 0 48px;
  }
`
const AboutHeading = styled.div`
  box-sizing: border-box;
  width: 100%;
  background: #fdfdfd;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  h1 {
    font-family: "Source Sans Pro";
    text-align: center;
    color: #305244;
    font-weight: 900;
  }
  h3 {
    color: #305244;
    font-weight: 900;
    margin: 32px 0 0;
  }
  p {
    margin-top: 16px;
    text-align: center;
    span {
      color: #305244;
      font-weight: 900;
    }
  }
  #Wave {
    display: none;
  }

  @media screen and (min-width: 450px) {
    padding: 32px 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 32px 48px;
  }
  @media screen and (min-width: 650px) {
    padding: 32px 64px;
  }
  @media screen and (min-width: 700px) {
    padding: 32px 64px 0;
    p {
      max-width: 80%;
    }
    #Wave {
      overflow: hidden;
      display: block;
      margin: 32px -64px -16px;
    }
  }
  @media screen and (min-width: 800px) {
    padding: 48px 72px 0;
    #Wave {
      margin: 48px -72px -16px;
    }
  }
  @media screen and (min-width: 950px) {
    padding: 48px 80px 0;
    #Wave {
      margin: 48px -80px -16px;
    }
  }
  @media screen and (min-width: 1050px) {
    padding: 48px 96px 0;
    #Wave {
      margin: 64px -96px -16px;
    }
  }
`

const AboutDetailsContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-family: "Source Sans Pro";
    text-align: center;
    font-weight: 900;
    color: #fdfdfd;
    margin-top: 32px;
  }
  @media screen and (min-width: 450px) {
    padding: 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 32px 48px;
  }
  @media screen and (min-width: 650px) {
    padding: 32px 64px;
  }
  @media screen and (min-width: 700px) {
    padding: 0 64px 32px;
  }
  @media screen and (min-width: 800px) {
    padding: 0 72px 48px;
    h1 {
      margin-top: 64px;
    }
  }
  @media screen and (min-width: 950px) {
    padding: 0 80px 48px;
    h1 {
      margin-top: 80px;
    }
  }
  @media screen and (min-width: 1050px) {
    padding: 0 96px 48px;
  }
`

const AboutDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  h2 {
    color: #fdfdfd;
    text-align: center;
  }
  p {
    margin-top: 12px;
    color: #fdfdfd;
    text-align: center;
  }
  @media screen and (min-width: 800px) {
    width: 50%;
    margin-top: 0;
    h2 {
      margin: 0;
    }
  }
`

const AboutImageContainer = styled.div`
  background: #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  min-width: 100%;
  /* padding-top: 75%; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  div {
    width: 100%;
    img {
      z-index: 0;
    }
  }
  @media screen and (min-width: 800px) {
    min-width: 45%;
    /* padding-top: 30%; */
    /* padding-top: 0; */
  }
`

const AboutDetailsImageContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 800px) {
    &:not(:first-child) {
      margin-top: 64px;
    }
    &:nth-child(1) {
      margin-top: 0;
      flex-direction: row-reverse;
      div {
        align-items: flex-end;
        h2,
        p {
          text-align: right;
        }
      }
    }
    &:nth-child(2) {
      flex-direction: row;
      div {
        align-items: flex-start;
        h2,
        p {
          text-align: left;
        }
      }
    }
    &:nth-child(4) {
      flex-direction: row-reverse;
      div {
        align-items: flex-end;
        h2,
        p {
          text-align: right;
        }
      }
    }
    &:nth-child(5) {
      flex-direction: row;
      div {
        align-items: flex-start;
        h2,
        p {
          text-align: left;
        }
      }
    }
    align-items: center;
    justify-content: space-between;
  }
`

const About = () => (
  <AboutContainer>
    <AboutHeading>
      <h1>We are King Alton</h1>
      {/* <p>King Alton Enterprises Inc. is a family owned distribution service. </p> */}
      <h3>Our Vision</h3>
      <p>
        To be the most sought after distributor for the provinces of Batangas
        and Oriental Mindoro.
      </p>
      <h3>Our Mission</h3>
      <p>
        <span>To our customers: </span> To satisfy customer and create loyalty
        by providing quality products from trusted principals, maintaining good
        service level and giving competitive pricing
      </p>
      <p>
        <span>To our principals: </span> To build and sustain strong
        relationships; and be a trusted, dependable distributor supported by
        effective sales, logistics, admin, and finance.
      </p>
      <h3>Core Values</h3>
      <p>
        <span>Operational Excellence: </span> We take pride in the quality of
        our works. We strive for excellence in servicing customers, principals
        and co-workers.
      </p>
      <p>
        <span>Positivity: </span> We choose to be positive. We make it
        contagious to boost the happiness, productivity and creativity of
        everyone.
      </p>
      <p>
        <span>Communication: </span> We believe that effective communication
        yields high productivity and produces a healthy work environment.
      </p>
      <p>
        <span>Honesty &amp; Integrity: </span> We believe that effective
        communication yields high productivity and produces a healthy work
        environment.
      </p>
      <p>
        <span>Adaptability: </span> We embrace change. We are open to new ideas
        and we never stop learning.
      </p>
      <p>
        <span>Focus: </span> We stay focused on what we do. There are many
        routes but there is only one direction.
      </p>
      <p>
        <span>Frugality: </span> We practice frugality and teach everyone to
        treat the company resources as their own to be mindful of purchases and
        usage.
      </p>
      <p>
        <span>Family: </span> We consider every member as part of our family. We
        treat all individuals with fairness, dignity and respect; but we also do
        not tolerate wrongdoings.
      </p>
      <svg
        id="Wave"
        viewBox="0 0 1440 169"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-393 169H1890C1890 169 1655.59 34.7134 1467.43 8.99321C1221.03 -24.6869 1099.07 46.785 847.021 50.8642C530.063 55.9938 277.925 -34.5074 -27.4034 19.3247C-225.167 54.1922 -393 169 -393 169Z"
          fill="#305244"
        />
      </svg>
    </AboutHeading>
    <AboutDetailsContainer>
      {/* 1 */}
      <AboutDetailsImageContainer>
        <AboutImageContainer>
          <AboutImage
            alt="Warehouse Drone Shot"
            filename="about/batangas.jpg"
          />
        </AboutImageContainer>
        <AboutDetails>
          <h2>What our business is</h2>
          <p>
            We specialize in distribution of consumer goods on a wholesale and
            retail basis in the provinces of Batangas and Oriental Mindoro. We
            are one of the most preferred distributors in the two provinces. Our
            sales team covers different channels from supermarkets, drugstores,
            groceries, cooperatives, market stalls and sari-sari stores.{" "}
          </p>
        </AboutDetails>
      </AboutDetailsImageContainer>
      {/* 2 */}
      <AboutDetailsImageContainer>
        <AboutImageContainer>
          <AboutImage alt="Warehouse Inside" filename="about/IMG_6556.jpg" />
        </AboutImageContainer>
        <AboutDetails>
          <h2>How we’ll help your business</h2>
          <p>
          In King Alton, we take pride in the quality of our works. We strive for excellence in servicing customers, principals and co-workers. We have been operating for over 23 years.  We have also generated substantial sales growth for the companies we’ve handled.  
          </p>
        </AboutDetails>
      </AboutDetailsImageContainer>
      {/* Next heading */}
      <h1>How we got here</h1>
      <AboutDetailsImageContainer>
        <AboutImageContainer></AboutImageContainer>
        <AboutDetails>
          <h2>Humble beginnings</h2>
          <p>
            KING ALTON ENTERPRISE INC. started as a small business enterprise
            registered under the single proprietorship of Mr. Venusito S. Solis
            last January 22, 1996 and has become a family corporation with SEC
            No. A200201349 in 2002.
          </p>
        </AboutDetails>
      </AboutDetailsImageContainer>
      <AboutDetailsImageContainer>
        <AboutImageContainer>
          <AboutImage alt="Warehouse Outside" filename="about/IMG_6551.jpg" />
        </AboutImageContainer>
        <AboutDetails>
          <h2>Expanding our reach</h2>
          <p>
            On 1998, the company expanded and moved to a warehouse in Brgy.
            Sampaga. A branch to serve the Mindoro province was opened on
            October 10, 2000. On May 2013, KAEI moved to its very own warehouse
            in Brgy. Dumantay where it’s currently located.
          </p>
        </AboutDetails>
      </AboutDetailsImageContainer>
    </AboutDetailsContainer>
  </AboutContainer>
)

export default About
export { AboutHeading }
